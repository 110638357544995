import React, { useState } from "react";
import { Link, useIntl } from "gatsby-plugin-intl";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import FlopGtoLogoDark from "../assets/image/flop/flop-gto-logo-dark.svg";
import FlopGtoBookImg from "../assets/image/flop/products/flop-gto/flop-gto-book-michael-acevedo.png";

const LeftSideStyled = styled.div`
  @media (min-width: 475px) {
    min-width: 400px;
  }
`;

const FormControlStyled = styled(Form.Control)`
  flex: 0 1 400px;
  &:focus {
    border-color: #43b99d;
  }
  @media (max-width: 863px) {
    flex: 1;
  }
`;

const ButtonStyled = styled(Button)`
  @media (max-width: 863px) {
    margin: auto;
  }
`;

const PokerTips = ({ location }) => {
  const intl = useIntl();

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [hasSubscribe, setHasSubscribe] = useState(false);

  const subscribe = async () => {
    setHasSubscribe(true);
    await fetch(
      "https://e1hs2ah1vg.execute-api.eu-west-1.amazonaws.com/Prod/subscribe",
      {
        method: "POST",
        headers: {
          "content-type": "Application/json",
        },
        body: JSON.stringify({
          email: mail.trim(),
          name: name.trim(),
          origin: "flop-gto",
          locale: intl.locale,
        }),
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <div style={{ padding: 16 }}>
        <Link to="/" style={{ display: "flex" }}>
          <img src={FlopGtoLogoDark} alt="Flop Gto LOGO Dark" />
        </Link>
      </div>
      <div
        style={{
          background: "linear-gradient(161.61deg, #1D222E 0%, #434652 99.58%)",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexWrap: "wrap-reverse",
            padding: "0 2rem",
          }}
        >
          {hasSubscribe ? (
            <div
              style={{
                alignItems: "center",
                color: "#fff",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontSize: 50,
                  marginBottom: 32,
                }}
              >
                {intl.formatMessage({
                  id: "gto-mailing-list.subscribed.title",
                })}
              </div>
              <div>
                {intl.formatMessage({
                  id: "gto-mailing-list.subscribed.subtitle1",
                })}
              </div>
              <div>
                {intl.formatMessage({
                  id: "gto-mailing-list.subscribed.subtitle2",
                })}
              </div>
            </div>
          ) : (
            <>
              <LeftSideStyled
                style={{
                  alignItems: "center",
                  display: "flex",
                  flex: "1 0 50%",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    display: "flex",
                    flex: "0 1 530px",
                    flexDirection: "column",
                    fontFamily: "Typo Round Light",
                  }}
                >
                  <div
                    style={{
                      fontSize: 50,
                      marginBottom: 32,
                    }}
                  >
                    {intl.formatMessage(
                      { id: "gto-mailing-list.title" },
                      { name: <b>FLOP Gto</b> }
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      marginBottom: 32,
                    }}
                  >
                    {intl.formatMessage({ id: "gto-mailing-list.subtitle" })}
                  </div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      !hasSubscribe && subscribe();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: 24 }}>
                      <FormControlStyled
                        onChange={({ target: { value } }) => setName(value)}
                        placeholder={intl.formatMessage({
                          id: "gto-mailing-list.form.name",
                        })}
                        required
                        value={name}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: 24 }}>
                      <FormControlStyled
                        onChange={({ target: { value } }) => setMail(value)}
                        placeholder={intl.formatMessage({
                          id: "gto-mailing-list.form.email",
                        })}
                        required
                        type="email"
                        value={mail}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: 16 }}>
                      <ButtonStyled
                        size="large"
                        style={{
                          border: "none",
                          borderRadius: 24,
                          backgroundColor: "#43B99D",
                          color: "#fff",
                          flex: "0 1 250px",
                        }}
                        type="submit"
                      >
                        {intl.formatMessage({
                          id: "gto-mailing-list.form.cta",
                        })}
                      </ButtonStyled>
                    </div>
                  </Form>
                </div>
              </LeftSideStyled>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flex: "1 0 50%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  src={FlopGtoBookImg}
                  alt="Modern Poker Theory Michael Acevedo"
                  style={{ height: "100%", width: "100%" }}
                  className="img-fluid"
                />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            color: "#fff",
            fontSize: 12,
            padding: 8,
            textAlign: "center",
          }}
        >
          &copy; {new Date().getFullYear()} WeOpt, All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default PokerTips;
